
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as architecte9UJZQpNdDPMeta } from "/vercel/path0/pages/architecte.vue?macro=true";
import { default as devis1pNp7mIeRFMeta } from "/vercel/path0/pages/devis.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as _91id_93DzfLFKEHUyMeta } from "/vercel/path0/pages/realisation/[id].vue?macro=true";
import { default as realisations1fA9EuhBycMeta } from "/vercel/path0/pages/realisations.vue?macro=true";
import { default as servicesoiqz5GGY44Meta } from "/vercel/path0/pages/services.vue?macro=true";
export default [
  {
    name: "architecte",
    path: "/architecte",
    component: () => import("/vercel/path0/pages/architecte.vue")
  },
  {
    name: "devis",
    path: "/devis",
    component: () => import("/vercel/path0/pages/devis.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/vercel/path0/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    component: () => import("/vercel/path0/pages/mentions-legales.vue")
  },
  {
    name: "realisation-id",
    path: "/realisation/:id()",
    component: () => import("/vercel/path0/pages/realisation/[id].vue")
  },
  {
    name: "realisations",
    path: "/realisations",
    component: () => import("/vercel/path0/pages/realisations.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/vercel/path0/pages/services.vue")
  }
]